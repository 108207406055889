import './Reset.css';
import './App.css';
import RoutesApp from './routes';


export default function App() {
  return (
    <RoutesApp />
  );
}
